<template>
    <div>
        <Navbar />
        <Header :category="'organizers'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <FacilityBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import FacilityBody from './FacilityBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'
    export default {
        name: 'FacilityMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_2_4"),
            }
        },
        components: {
            Navbar,
            Header,
            FacilityBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: "FACUKUTUES<br>SERVICE",
                borrowSpaceCrumb: [{
                    name: this.$t('navbar.menu_2')
                }]
            }
        }
    }
</script>